$orange: #fa6d1c;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#mobile-carousel-header {
  display: none;
}

.dots, .mobile-icon {
  display: none;
}

@media screen and (max-width: 1200px){
  p {
    font-size: 16px;
  }

  // appointment type listing view
  .home-container {
    z-index: 1;
    width: 100%;
    padding: 50px;
    opacity: 100%;

    .content {
      h2 {
        font-size: 42px;
      }

      ul li a.active {
        font-weight: normal;
        color: white;

        svg {
          display: none;
        }
      }
    }
  }

  .carousel:not(.primary) {
    display: none;
  }

  #mobile-carousel-header {
    display: none;
  }

  // carousel view
  #mobile-carousel-header.active {
    display: block;
    position: absolute;
    padding: 50px;
    z-index: 9;
    left: 0;
    top: 0;
  }

  .carousel {

    .carousel-control-next {
      right: 20px;
    }

    .carousel-control-prev {
      left: 20px;
    }

    .carousel-control-next, .carousel-control-prev {
      width: 10%;

      img {
        width: 48px;
      }
    }

    .carousel-item {
      background-position: right;

      .mobile-icon {
        display: block;
        position: absolute;
        right: 6%;
        top: 30px;
        max-width: 25%;
      }

      .carousel-caption {
        text-align: left;
        right: 12%;
        left: 12%;

        img {
          display: none;
        }

        h3 {
          font-size: 28px;
          margin-bottom: 50px;
          max-width: 50%; // to force break after second word
        }

        .btn-primary {
          width: 100%;
          font-size: 12px;
        }

        .dots {
          display: block;
          margin: 60px auto;
          text-align: center;

          .dot {
            border: 1px solid white;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            display: inline-block;
            margin: 2px;

            &.active {
              background: white;
            }
          }
        }
      }

    }

  }

  .home-container:not(.primary) {
    display: none;
  }

  // booking form
  #header, .header a, .questions-container {
    display: none;
  }

  .content-container {
    display: block;
  }

  .details-container, .form-container {
    width: 100%;
  }

  .details-container {
    padding: 0 30px;
    background: $orange;

    .sidebar-content {
      padding: 10px 0;

      h2.title {
        margin: 0;
        padding: 16px 0;
        text-align: left;
      }
    }

    img {
      display: none;
    }
  }

  .form-container {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 767px) {
  img.sidebar-logo {
    max-width: 60%;
  }

  .form-container .reminder-container .reminder {
    position: initial;
    margin-top: 16px;
  }

  .info-container.choose-orth {
    display: block;
  }

  label[for=orth-assessment], label[for=orth-adjustment] {
    width: 100%;
  }

  label[for=orth-assessment] {
    margin-bottom: 10px;
  }
}

