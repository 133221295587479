.btn-container {
    display: flex;
    justify-content: center;

    .btn-primary {
        padding: 15px;
    }

    button {
        width: 75px;
        margin: 5px;
    }

    button:disabled {
        opacity: 0.5;
        background-color: #FE630C;
    }
}


@media screen and (max-width: 768px) {
    .ReactModal__Content {
        width: unset !important;
        overflow-y: scroll !important;
    }
}