$label: #727272;
$orange: #fa6d1c;

.form-container {
    padding: 20px 60px;
    width: 60%;

    h4 {
        font-size: 20px;
        font-weight: 500;
        margin: 20px 0 5px 0;
    }

    .form-label, .form-check label {
        color: $label;
        font-size: 18px;
        font-weight: 500;
    }

    .form-check {
        display: flex;
        align-items: self-start;
        height: auto;
        margin-top: 5px;

        label {
            margin: -1px 0 0 5px;
        }
    }

    .form-select::after {
        color: grey;
    }

    input[type=checkbox] {
        min-width: 16px;
    }

    .btn-default {
        margin-top: 20px;
    }

    .form-check.reminder {
        margin: 0;
        border: 1px solid #ced4da;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;

        input {
            float: none;
            margin: 0;
        }

        label {
            margin-top: 0;
        }
    }

    .reminder-container {
        position: relative;

        .reminder {
            position: absolute;
            left: 12px;
            right: 12px;
            bottom: 0;
        }
    }

}

.guardian-container {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 15px;

    .form-check {
        margin: 0 0 15px 0 !important;
    }
}

#birthday-fields {
    display: flex;
}

#birthday-fields select {
    font-size: 12px;
    flex: 1;

    @media screen and (max-width: 767px) {
        background-size: 16px 12px;
        padding: 0.55rem 1.25rem 0.55rem 0.55rem;
        background-position: right 0.15rem center;

        select[name="birthDay"] {
            flex: 0 0 60px !important;
        }

        select[name="birthYear"] {
            flex: 0 0 80px !important;
        }
    }

}

h4 {
    color: $orange;
}

.error {
    border: 2px solid #FF6565;
}

.error-message {
    color: #FF6565;
    padding-top: 5px;
}

button[type=submit].acknowledgements-complete {
    background-color: $orange;
}

#date-options {
    .time {
        padding: 0;
        margin: 0 0 5px 0;

        input[type="radio"] {
            display: none;

            & + label {
                font-size: 15px;
                padding: 10px;
                width: 100%;
                border: 1px solid #E2E1E5;
                border-radius: 5px;
                margin: 0;
                text-align: center;
                text-transform: uppercase;
            }
        }

        input[type="radio"]:hover + label {
            background-color: $orange;
            color: white;
            cursor: pointer;
        }

        input[type="radio"]:checked + label {
            background-color: $orange;
            color: white;
            font-weight: 600;
        }
    }
}

#modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: none;
    background: none;
}


