@import url("./fonts.css");

$primaryBtn: #FE630C;
$defaultBtn: #CFCFCF;
$primaryOrange: #fa6c1d;
$label: #727272;

html, body {
    font-family: "Futura", sans-serif;
    font-weight: 400;
}

.bold {
    font-weight: bold;
}

p, ul li {
    font-size: 18px;
}

.btn-primary {
    background-color: $primaryBtn;
    border: none;
    font-size: 15px;
    font-weight: bold;
    padding: 18px 40px;

    &:focus, &:hover {
        background-color: $primaryBtn;
    }
}

.btn-default {
    background-color: $defaultBtn;
}

.note {
    color: #FFB68D;
}

#booking-container {
    display: block;
    min-height: 100vh;
    position: relative;
}

#content-wrapper {
    position: relative;
}

.content-container {
    display: flex;
    align-items: stretch;
}

.info-container {
    padding: 25px;
    background: #d3d3d321;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-bottom: 30px;
    font-size: 15px;

    &.choose-orth {
        display: flex;
    }
}

label {
    color: $label;
}

.radio-label {
    color: #727272;
    font-size: 14px;
    font-weight: 500;
    margin-right: 7px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 50%;
    padding: 16px 20px;
}

.radio-input {
    margin: 0;
    visibility: hidden;
}

.custom-radio .font-awesome-check {
    display: none;
}

label:has(.radio-input:checked){
    color: black;
}

.radio-input:checked + span {
    border: none;
    background-color: $primaryOrange;
    position: relative;

    .font-awesome-check {
        display: block;
        color: white;
        width: 9px;
        position: absolute;
        left: 4px;
        top: 2px;
    }
}

.radio-input:checked + span:after {
    opacity: 1;
}

.custom-radio {
    left: -10px;
    top: 3px;
    cursor: pointer;
    width: 17px;
    height: 17px;
    border: 1px solid #ced4da;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

/* for inner filled circle */
.custom-radio::after {
    width: 12px;
    height: 12px;
    background: #f4b400;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.logo {
    max-height: 70px;
}

.sidebar-logo {
    max-width: 266px;
    width: 100%;
}

.header {
    padding: 30px;
    background-image: url('../assets/booking/header-bg-booking.png');
    background-size: cover;

    a {
        padding: 20px 60px 20px 20px;
        max-height: 10px;
    }
}

.home-container {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #FA6C1D;
    background-image: url('../assets/home/sidebar-overlay.png');
    background-size: cover;
    opacity: 85%;
    width: 600px;
    padding: 60px 90px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .content {
        flex: 1;
        color: white;
        padding: 120px 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 20px 0;

            li {
                border-bottom: 1px dotted white;
                padding: 5px 0;

                a {
                    font-size: 22px;
                    font-weight: 500;
                    text-decoration: none;
                    color: white;
                    display: block;
                    width: 100%;

                    svg {
                        display: none;
                        float: right;
                    }

                    &.active {
                        font-weight: bold;
                        color: #FFEFC6;
                        display: flex;
                        align-items: center;

                        svg {
                            display: block;
                            width: 14px;
                            color: #FFEFC6;
                            margin-left: auto;
                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                a::after {
                    width: 12px;
                    height: 12px;
                    background: red;
                }
            }
        }

        h2 {
            font-family: 'Futura', sans-serif;
            font-weight: bold;
            font-size: 50px;
            margin-bottom: 16px;
        }

        h3 {
            font-size: 20px;
            font-weight: bold;
        }

        p.bold {
            margin-bottom: 10px;
            font-size: 17px;
        }

        span.chat-link {
            display: flex;
            align-items: center;

            a {
                color: white;
                font-size: 17px;
            }

            svg {
                margin-left: 5px;
            }
        }
    }

    .sidebar-footer {
        flex-shrink: 0;
        color: white;

        p, a {
            font-size: 17px;
            font-weight: normal;
            color: white;
        }

        a {
            white-space: nowrap;
        }
    }
}

#confirmation {
    background-image: url('../assets/booking/confirmation-bg.png');
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    padding: 100px;
    color: white;

    #confirmation-content {
        width: 100%;
        max-width: 679px;
        margin: 0 auto;

        h3 {
            font-weight: bold;
            font-size: 26px;
            line-height: 1.25;
            margin-bottom: 12px;
        }

        h4 {
            font-weight: normal;
            font-size: 26px;
            color: white;
        }

        p.blurb {
            font-size: 22px;
            margin-top: 20px;
        }

        img {
            width: 242px;
            max-width: 100%;
        }

        #confirmation-logo {
            width: 397px;
            max-width: 100%;
            display: block;
            margin: 0 auto 160px auto;
        }

        .appointment-details {
            p {
                font-size: 16px;
            }
        }

        #confirmation-footer {
            text-align: center;
            margin-top: 20px;

            a {
                color: white;
                padding: 0 10px;
                font-size: 17px;
                border-right: 1px solid white;
                text-decoration: none;
                text-transform: uppercase;

                &:last-of-type {
                    border-right: none;
                }
            }
        }
    }
}

.details-container img {
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 767px){
    #confirmation {
        padding: 50px;

        #confirmation-content #confirmation-logo {
            margin-bottom: 50px;
        }
    }
}

@media screen and (max-width: 575px){
    #confirmation #confirmation-content img {
        display: none;
    }
}

