@font-face {
    font-family: Futura;
    src: url("../fonts/FuturaStd-Book.otf") format("opentype");
}

@font-face {
    font-family: Futura;
    font-weight: 400;
    src: url("../fonts/FuturaStd-Medium.otf") format("opentype");
}

@font-face {
    font-family: Futura;
    font-weight: bold;
    src: url("../fonts/FuturaStd-Bold.otf") format("opentype");
}