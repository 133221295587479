#header {
    position: absolute;
    top: 130px;
    bottom: 0;
    background-image: url('../../assets/booking/nav-bg-booking.png');
    background-size: cover;
    z-index: 1;
    width: 100px;

  .ps-sidebar-root {
    border-right-width: 0;

    .ps-sidebar-container {
      background-color: transparent !important;

      .ps-menuitem-root {
        a {
          border-left: 5px solid transparent;
        }

        &:hover{
          cursor: pointer;

          a {
            background-color: #91441c;
          }
        }

        &.ps-active {
          background-color: #91441c;
          border-left: 5px solid #F0CD74;
        }
      }
      .ps-menu-button {
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ps-menu-label {
        display: none;
      }

      .ps-menu-icon {
        margin: 0;

        img{
          width: 50px;
        }
      }
    }
  }
}


