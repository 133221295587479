$orange: #fa6d1c;

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.slide-container {
  display: flex;
}

.carousel {
  display: block;
  width: 100%;
  height: 100vh;
  min-height: 1032px; // height of sidebar
}

.carousel-control-prev img, .carousel-control-next img {
  width: 93px;
}

.carousel-inner, .carousel-item {
  height: 100%;
  width: 100%;
}

.carousel-control-prev {
  left: 600px;
}

.carousel-item {
  background-size: cover;

  .carousel-caption {
    padding: 40px 0 40px 600px; // add width of sidebar
    top: 10%;
    bottom: auto;

    img {
      max-height: 250px;
    }

    h3 {
      font-size: 26px;
      font-weight: bold;
      margin: 20px 0 15px 0;
    }

    p a {
      color: white;
    }

    a.btn-primary {
      margin-top: 40px;
    }
  }

  &.custom-orthotics {
    background-image: url('../../assets/home/custom-orthotics-master-bg.png');
  }

  &.anywhere {
    background-image: url('../../assets/home/anywhere-master-bg.png');
  }

  &.footwear {
    background-image: url('../../assets/home/footwear-master-bg.png');
  }

  &.bracing {
    background-image: url('../../assets/home/bracing-master-bg.png');
  }

  &.compression {
    background-image: url('../../assets/home/compression-master-bg.png');
  }
}

@media screen and (max-width: 1200px) {
  .carousel {
    .carousel-caption {
      padding: 40px;
    }
  }
}

@media screen and (max-width: 767px) {
  .carousel {
    background: $orange;

    .carousel-item {
      background-size: cover;
      background-position: top !important;

      &.custom-orthotics {
        background-image: url('../../assets/home/mobile/bg-custom-orthotics.png');
      }

      &.anywhere {
        background-image: url('../../assets/home/mobile/bg-kintec-anywhere.png');
      }

      &.footwear {
        background-image: url('../../assets/home/mobile/bg-footwear.png');
      }

      &.bracing {
        background-image: url('../../assets/home/mobile/bg-bracing.png');
      }

      &.compression {
        background-image: url('../../assets/home/mobile/bg-compression.png');
      }
    }
  }
}