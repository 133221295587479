@import url("../../styles/fonts.css");

.details-container {
    padding-left: 100px;
    width: 40%;
    background-image: url('../../assets/booking/sidebar-bg-booking.png');
    background-size: cover;
    color: white;
    position: relative;

    p, ul li {
        font-size: 15px;
    }

    .sidebar-content {
        padding: 0 40px;

        a {
            color: white;
        }
    }

    img {
        margin: auto;
        max-height: 100%;
        max-width: 100%;
    }
}

.title {
    margin: 20px 0;
    line-height: 1.25;
    font-size: 26px;
    font-weight: 400;
    text-align: center;
}

.questions-container {
    position: absolute;
    bottom: 0;
    padding: 20px;

    p, a {
        font-size: 17px;
        font-weight: normal;
        color: white;
    }

    a {
        white-space: nowrap;
    }
}